import React from "react";
import Grid from "@components/Grid";
import DrawLine from "./DrawLine";
import "./style.css";

function ContactLine() {
  return (
    <div className="contact-line">
      <Grid.Container fluid>
        <Grid.Row>
          <Grid.Cell mobileColumns={4} columns={12} offset={0} className="cell">
            <DrawLine
              uktel="+44 123 456 789"
              ukmail="hello@rollstudio.co.uk"
              ittel="+39 123 456 789"
              itmail="hello@rollstudio.it"
            />
          </Grid.Cell>
        </Grid.Row>
      </Grid.Container>
    </div>
  );
}

export default ContactLine;
